import React, { useCallback } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { parseFloat } from 'modules/main/formaters';
import {
  Modal,
  TextInput,
  Button,
  FormRadioButton,
  Tooltip,
} from 'modules/ui/components';
import {
  settingsDefaultsWithDefaultValuesSelector,
  settingsWindSpeedCorrectionSelector,
  saveSettingsThunk,
  settingsTimeResoulutionDefaultSelector,
} from 'modules/settings';
import ResolutionChartElement from 'modules/home/components/ResolutionChartElement';
import { TimeResolution } from 'modules/meteo/consts';
import {
  validateRequiredNumber,
  validateCorrectionPercentage,
  validateSpeedLimit,
} from 'modules/form';
import { LocationApiModule } from 'modules/api';
import WindSpeedCorrections from '../WindSpeedCorrections';

import {
  MultiRow,
  ScaleContainer,
  FooterContainer,
  RadioButtonWrapper,
} from './styledComponents';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

const SettingsModal: React.FC<Props> = ({ isOpen, closeModal }) => {
  const dispatch = useDispatch();
  const defaults = useSelector(settingsDefaultsWithDefaultValuesSelector);
  const windSpeedCorrection = useSelector(settingsWindSpeedCorrectionSelector);
  const timeResolution = useSelector(settingsTimeResoulutionDefaultSelector);
  const onSubmit = useCallback(
    async (data) => {
      dispatch(saveSettingsThunk(data));
      closeModal();
    },
    [closeModal, dispatch],
  );

  return (
    <Modal
      isOpen={isOpen}
      width={712}
      title="Settings"
      closeModal={closeModal}
      onRequestClose={closeModal}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={{ defaults, windSpeedCorrection, timeResolution }}
        render={({ handleSubmit, invalid, values }): React.ReactNode => (
          <>
            <Tabs>
              <TabList>
                <Tab>Defaults</Tab>
                <Tab>Wind speed corrections</Tab>
                {false && <Tab>Timeline resolution</Tab>}
              </TabList>
              <TabPanel forceRender>
                <MultiRow>
                  <Field
                    name="defaults.polarEfficiency"
                    parse={parseFloat}
                    component={TextInput}
                    label="Polar efficiency"
                    rightInlineLabel="%"
                    width={133}
                    validate={validateCorrectionPercentage}
                  />
                  <Field
                    name="defaults.waveCorrection"
                    parse={parseFloat}
                    component={TextInput}
                    label="Wave correction"
                    rightInlineLabel="%"
                    width={133}
                    validate={validateCorrectionPercentage}
                  />
                  <Field
                    name="defaults.currentCorrection"
                    parse={parseFloat}
                    component={TextInput}
                    label="Current correction"
                    rightInlineLabel="%"
                    width={133}
                    validate={validateCorrectionPercentage}
                  />
                  <Field
                    name="defaults.speedLimit"
                    parse={parseFloat}
                    component={TextInput}
                    label="Wind speed limit"
                    rightInlineLabel="kts"
                    width={133}
                    validate={validateSpeedLimit}
                  />
                </MultiRow>
                <MultiRow>
                  <Field
                    name="defaults.sailChangePenalty"
                    parse={parseFloat}
                    component={TextInput}
                    label="Sail change penalty"
                    rightInlineLabel="min"
                    width={133}
                    validate={validateRequiredNumber}
                  />
                  <Field
                    name="defaults.tackPenalty"
                    parse={parseFloat}
                    component={TextInput}
                    label="Tack penalty"
                    rightInlineLabel="min"
                    width={133}
                    validate={validateRequiredNumber}
                  />

                  <Field
                    name="defaults.gybePenalty"
                    parse={parseFloat}
                    component={TextInput}
                    label="Gybe penalty"
                    rightInlineLabel="min"
                    width={133}
                    validate={validateRequiredNumber}
                  />
                </MultiRow>
                <MultiRow>
                  <Field
                    name="defaults.waveHeightLimit"
                    parse={parseFloat}
                    component={TextInput}
                    label="Wave height limit"
                    rightInlineLabel="m"
                    width={133}
                    validate={validateRequiredNumber}
                  />
                  <Field
                    name="defaults.url"
                    type="text"
                    component={TextInput}
                    label="URL for live boat data"
                    width={400}
                  />
                  {values?.defaults?.url ===
                    LocationApiModule.getCurrentLocation && (
                    <div style={{ marginTop: 16, marginLeft: -16 }}>
                      <Tooltip
                        multiline
                        text="This is just a temporary setting that always returns the boat position at Les Sables-d’Olonne.<br> Reach out to us via email (contact@doradosail.com) to set you up with live boat data."
                      />
                    </div>
                  )}
                </MultiRow>
                <MultiRow />
              </TabPanel>
              <TabPanel forceRender>
                <WindSpeedCorrections />
              </TabPanel>
              {false && (
                <TabPanel forceRender>
                  <div>
                    <MultiRow>
                      <RadioButtonWrapper>
                        <Field
                          name="timeResolution"
                          component={FormRadioButton}
                          label="High (1h / 3h / 6h)"
                          value={TimeResolution.high}
                          type="radio"
                          parse={parseFloat}
                        />
                      </RadioButtonWrapper>
                      <ScaleContainer>
                        <ResolutionChartElement backgroundColor="#6C120A" />

                        <ResolutionChartElement backgroundColor="#C7291C" />

                        <ResolutionChartElement backgroundColor="#EB3A2E" />
                      </ScaleContainer>
                    </MultiRow>
                    <MultiRow>
                      <RadioButtonWrapper>
                        <Field
                          name="timeResolution"
                          component={FormRadioButton}
                          label="Medium (3h / 3h / 6h)"
                          value={TimeResolution.medium}
                          type="radio"
                          parse={parseFloat}
                        />
                      </RadioButtonWrapper>
                      <ScaleContainer>
                        <ResolutionChartElement backgroundColor="#C7291C" />
                        <ResolutionChartElement backgroundColor="#C7291C" />
                        <ResolutionChartElement backgroundColor="#EB3A2E" />
                      </ScaleContainer>
                    </MultiRow>
                    <MultiRow>
                      <RadioButtonWrapper>
                        <Field
                          name="timeResolution"
                          component={FormRadioButton}
                          label="Low (3h / 6h / 12h)"
                          value={TimeResolution.low}
                          type="radio"
                          parse={parseFloat}
                        />
                      </RadioButtonWrapper>
                      <ScaleContainer>
                        <ResolutionChartElement backgroundColor="#C7291C" />
                        <ResolutionChartElement backgroundColor="#EB3A2E" />
                        <ResolutionChartElement backgroundColor="#F1A29F" />
                      </ScaleContainer>
                    </MultiRow>
                    <MultiRow>
                      <RadioButtonWrapper>
                        <Field
                          name="timeResolution"
                          component={FormRadioButton}
                          label="Superlow (12h / 12h / 12h)"
                          value={TimeResolution.superlow}
                          type="radio"
                          parse={parseFloat}
                        />
                      </RadioButtonWrapper>
                      <ScaleContainer>
                        <ResolutionChartElement backgroundColor="#F1A29F" />
                        <ResolutionChartElement backgroundColor="#F1A29F" />
                        <ResolutionChartElement backgroundColor="#F1A29F" />
                      </ScaleContainer>
                    </MultiRow>
                  </div>
                </TabPanel>
              )}
            </Tabs>
            <FooterContainer>
              <Button label="Cancel" isSecondary clickHandler={closeModal} />
              <Button
                label="Save"
                disabled={invalid}
                clickHandler={handleSubmit}
              />
            </FooterContainer>
          </>
        )}
      />
    </Modal>
  );
};

export default React.memo(SettingsModal);
