import React, { memo } from 'react';
import { Field, Form } from 'react-final-form';

import {
  InputLabel,
  Modal,
  ModalFooter,
  Button,
  TextInput,
} from 'modules/ui/components';
import { CoordinatesInput } from 'modules/coordinates';
import { validateHeading } from 'modules/form';
import { useSetCustomCurrentLocation } from '../../hooks';

import { ContentRow, FormContainer } from './styledComponents';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

const LocationInputModalUi: React.FC<Props> = ({ closeModal }) => {
  const {
    initialValues,
    handleSetCustomCurrentLocation,
  } = useSetCustomCurrentLocation(closeModal);
  return (
    <Form
      onSubmit={handleSetCustomCurrentLocation}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, invalid }): React.ReactNode => (
        <>
          <FormContainer>
            <InputLabel>Custom coordinate</InputLabel>
            <ContentRow>
              <CoordinatesInput isLat name="latitude" />
              <CoordinatesInput name="longitude" />
            </ContentRow>
            <ContentRow>
              <Field
                name="heading"
                parse={parseFloat}
                component={TextInput}
                label="Heading"
                rightInlineLabel="°"
                width={80}
                validate={validateHeading}
              />
            </ContentRow>
          </FormContainer>
          <ModalFooter>
            <Button isSecondary label="Cancel" clickHandler={closeModal} />
            <Button
              isLoading={submitting}
              disabled={invalid || submitting}
              label="Save"
              clickHandler={handleSubmit}
            />
          </ModalFooter>
        </>
      )}
    />
  );
};

export const LocationInputModal = memo((props: Props) => (
  <Modal
    isOpen={props.isOpen}
    width={720}
    title="Set new boat location"
    closeModal={props.closeModal}
    onRequestClose={props.closeModal}
  >
    <LocationInputModalUi {...props} />
  </Modal>
));
