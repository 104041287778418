import { RoutingModule } from 'modules/api';
import { apiClient } from 'modules/apiClient';
import { Route, RoutingFiles } from '../types';

type SubmitNewRoutingProps = {
  models: string[];
  waypoints: number[][];
  name: string;
  boatId: number;
  startTime?: string;
  windSpeedCorrections?: Record<string, unknown>;
  maxWind?: number;
  maxWave?: number;
  sailChangePenalty?: number;
  gybePenalty?: number;
  tackPenalty?: number;
  waveCorrection?: number;
  currentCorrection?: number;
  polarSpreadCalculation?: boolean;
  obeyExclusionZones?: boolean;
  polarEfficiency?: number;
  inclusionZoneId?: number;
};

const submitNewRoutingApiCall = async (
  data: SubmitNewRoutingProps,
): Promise<void> => {
  await apiClient.post(RoutingModule.Base, data);
};

const listAllServerRoutings = async (boatId: number): Promise<Route[]> => {
  const response = await apiClient.get(RoutingModule.List, {
    params: {
      boatId,
    },
  });
  return response.data;
};

const getRouting = async (
  routingId: number | string,
): Promise<RoutingFiles> => {
  const response = await apiClient.get(`${RoutingModule.Base}/${routingId}`);
  return response.data;
};

const deleteServerRouting = async (routingId: string): Promise<void> => {
  await apiClient.delete(`${RoutingModule.Base}/${routingId}`);
};

export const RoutingApi = {
  submitNewRoutingApiCall,
  listAllServerRoutings,
  getRouting,
  deleteServerRouting,
};
