import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Map,
  Popup,
  MapMouseEvent,
  MapboxGeoJSONFeature,
  EventData,
} from 'mapbox-gl';
import useDeepCompareEffect from 'use-deep-compare-effect';
import ReactTooltip from 'react-tooltip';

import { Button, Modal } from 'modules/ui/components';
import { useModal } from 'modules/main';
import { getGeoJsonFromLocalForage } from 'modules/localForage/utils';
import db from 'modules/localForage/db';
import {
  emptyGeojson,
  RoutingLayers,
  RoutingLayersSources,
} from 'modules/mapLayers/consts';
import { isRoutingDataOnSelector } from 'modules/metadata';
import { formatDateHoverInfo } from 'modules/routing/utils/time';
import { Colors } from 'modules/ui/theme/colors';
import { positionTimeOnRoutePointThunk } from 'modules/routing/redux/thunk';
import { makeLineFromFeatureCollectionPoints } from 'modules/geojson/utils';
import {
  activeIsochronesStorageKeySelector,
  sortedSideMenuRoutingIdsSelector,
} from '../../redux/selectors';
import AllRoutes from '../AllRoutesModal';
import RoutingItem from '../SideMenuRouteItem';
import AsembleRoutingStatsModal from '../AsembleRoutingStatsModal';
import { DownloadedRoute } from '../../types';
import { SideMenuRoutesIndexes } from '../../consts';

import { RoutingsContainer, MainContainer } from './styledComponents';

type Props = {
  map: Map;
};

const RouteList: React.FC<Props> = ({ map }) => {
  const dispatch = useDispatch();
  const [isAllRoutesOpen, , openAllRouts, closeAllRoutes] = useModal(false);
  const [routingPlotId, setRoutingPlotId] = useState<string | null>(null);
  const sideMenuRoutingIds = useSelector(sortedSideMenuRoutingIdsSelector);
  const isRoutingDataOn = useSelector(isRoutingDataOnSelector);
  const activeIsochronesStorageKey = useSelector(
    activeIsochronesStorageKeySelector,
  );

  const popup = useMemo(
    () =>
      new Popup({
        closeButton: false,
        closeOnClick: false,
      }),
    [],
  );

  const onMouseEnter = useCallback(
    (
      e: MapMouseEvent & {
        features?: MapboxGeoJSONFeature[] | undefined;
      } & EventData,
    ) => {
      map.getCanvas().style.cursor = 'pointer';
      if (e.features) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const coordinates = e.features[0].geometry.coordinates.slice();
        const { COG, DT, SOG, TWA, TWD, TWS, WG, Sail, polarSpread } = e // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .features[0].properties as { [key: string]: any };
        const innerHtml = `${
          polarSpread
            ? `<strong>Polar spread: ${polarSpread}%</strong></br>`
            : ''
        }Date time: ${formatDateHoverInfo(DT)}<br/>TWS${
          WG ? '/gusts' : ''
        }: ${Number(TWS).toFixed(1)} kts${
          WG ? `, ${Number(WG).toFixed(1)} kts` : ''
        } <br/>TWD: ${TWD}°<br/>TWA: <span style="color:${
          TWA < 0 ? Colors.Destructive : Colors.Secondary
        }"> ${TWA < 0 ? 'Port' : 'Starboard'}</span> ${Math.abs(
          TWA,
        )}°<br/>SOG/COG: ${Number(SOG).toFixed(
          1,
        )} kts, ${COG}°<br/>Sails: ${Sail}<br/>`;
        popup.setLngLat(coordinates).setHTML(innerHtml).addTo(map);
      }
    },
    [popup, map],
  );

  const activeSources = useRef([] as string[]);

  const onMouseLeave = useCallback(() => {
    map.getCanvas().style.cursor = '';
    popup.remove();
    activeSources.current.forEach((source) => {
      map.removeFeatureState({
        source,
      });
    });
  }, [popup, map]);

  const onMouseEnterEnsemble = useCallback(
    (
      e: MapMouseEvent & {
        features?: MapboxGeoJSONFeature[] | undefined;
      } & EventData,
    ) => {
      if (e.features && e.features[0]?.properties?.distance) {
        e.originalEvent.stopPropagation();
        if (e.features[0].source && e.features[0]?.id) {
          activeSources.current.push(
            e.features[0].source,
            RoutingLayersSources.Izochrones,
          );
          activeSources.current.forEach((source) => {
            map.removeFeatureState({
              source,
            });
          });
          map.setFeatureState(
            {
              source: e.features[0].source,
              id: e.features[0]?.id,
            },
            { hover: true },
          );
          map.setFeatureState(
            {
              source: RoutingLayersSources.Izochrones,
              id: e.features[0]?.id,
            },
            { hover: true },
          );
        }
        map.getCanvas().style.cursor = 'pointer';
        if (e.features) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const { distance, etime, isSlowest, isFastest, isMedian } = e
            .features[0].properties as {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            [key: string]: any;
          };
          if (!distance || !etime) return;
          let firstLine = '';
          if (isFastest) {
            firstLine = '<b>FASTEST</b><br/>';
          }
          if (isMedian) {
            firstLine = '<b>MEDIAN</b><br/>';
          }
          if (isSlowest) {
            firstLine = '<b>SLOWEST</b><br/>';
          }
          const innerHtml = `${firstLine}End time: ${formatDateHoverInfo(
            etime,
          )}<br/>Distance: ${Number(distance).toFixed(1)} nm`;
          popup.setLngLat(e.lngLat).setHTML(innerHtml).addTo(map);
        }
      }
    },
    [popup, map],
  );

  const onRoutePointClick = useCallback(
    (
      e: MapMouseEvent & {
        features?: MapboxGeoJSONFeature[] | undefined;
      } & EventData,
    ) => {
      try {
        const features = map.queryRenderedFeatures(e.point);
        const layer = features.find((f) =>
          f.layer.id.startsWith(RoutingLayers.TimedOptimalRoute),
        );
        if (layer?.properties?.DT) {
          dispatch(positionTimeOnRoutePointThunk(layer?.properties?.DT));
        }
      } catch (error) {
        console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  useEffect(() => {
    SideMenuRoutesIndexes.forEach((index) => {
      map.on(
        'mouseenter',
        `${RoutingLayers.TimedOptimalRoute}${index}`,
        onMouseEnter,
      );
      map.on(
        'mouseleave',
        `${RoutingLayers.TimedOptimalRoute}${index}`,
        onMouseLeave,
      );
      map.on(
        'mouseenter',
        `${RoutingLayers.RoutingPolarSpreadPoints}${index}`,
        onMouseEnter,
      );
      map.on(
        'mouseleave',
        `${RoutingLayers.RoutingPolarSpreadPoints}${index}`,
        onMouseLeave,
      );
      map.on(
        'click',
        `${RoutingLayers.TimedOptimalRoute}${index}`,
        onRoutePointClick,
      );
      map.on(
        'mouseenter',
        `${RoutingLayers.OptimalRoute}${index}`,
        onMouseEnterEnsemble,
      );
      map.on(
        'mouseleave',
        `${RoutingLayers.OptimalRoute}${index}`,
        onMouseLeave,
      );
      map.on(
        'mouseenter',
        `${RoutingLayers.DashedOptimalRoute}${index}`,
        onMouseEnterEnsemble,
      );
      map.on(
        'mouseleave',
        `${RoutingLayers.DashedOptimalRoute}${index}`,
        onMouseLeave,
      );
    });
  }, [
    popup,
    map,
    onMouseEnter,
    onMouseLeave,
    onRoutePointClick,
    onMouseEnterEnsemble,
  ]);

  const handleIsochronesChange = useCallback(
    async (izochronesKey: string) => {
      try {
        if (!izochronesKey) {
          map
            .getSource(RoutingLayersSources.Izochrones)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .setData(emptyGeojson);
        }
        const izochrones = await getGeoJsonFromLocalForage(
          db.routings,
          izochronesKey,
        );
        let modifiedIzochrones = izochrones;
        if (izochrones && !izochrones?.features[0]?.id) {
          modifiedIzochrones = {
            ...izochrones,
            features: izochrones.features.map((feature) => ({
              ...feature,
              properties: { ...feature.properties, display: true },
            })),
          };
        }
        map
          .getSource(RoutingLayersSources.Izochrones)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .setData(modifiedIzochrones);
      } catch (error) {
        if (map) {
          map
            .getSource(RoutingLayersSources.Izochrones)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .setData(emptyGeojson);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    handleIsochronesChange(activeIsochronesStorageKey);
  }, [activeIsochronesStorageKey, handleIsochronesChange]);

  const handleSourceChange = useCallback(
    async (routing: DownloadedRoute, index: number) => {
      if (routing.optimalRouteStorageId && routing.timedOptimalRouteStorageId) {
        const [route, timedRoute] = await Promise.all([
          getGeoJsonFromLocalForage(db.routings, routing.optimalRouteStorageId),
          getGeoJsonFromLocalForage(
            db.routings,
            routing.timedOptimalRouteStorageId,
          ),
        ]);
        try {
          const line = route && makeLineFromFeatureCollectionPoints(route);
          map
            .getSource(`${RoutingLayersSources.OptimalRoute}${index}`)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .setData(line);
          const modifiedTimedOptimalRoute = {
            ...timedRoute,
            features: timedRoute?.features.map((f) => ({
              ...f,
              properties: {
                ...f.properties,
                isMidnight: f.properties?.DT.endsWith('T00:00:00') || false,
              },
            })),
          };
          map
            .getSource(`${RoutingLayersSources.TimedOptimalRoute}${index}`)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .setData(modifiedTimedOptimalRoute || emptyGeojson);
        } catch (error) {
          if (map) {
            map
              .getSource(`${RoutingLayersSources.OptimalRoute}${index}`)
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              .setData(emptyGeojson);
            map
              .getSource(`${RoutingLayersSources.TimedOptimalRoute}${index}`)
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              .setData(emptyGeojson);
          }
        }
      }
      if (
        routing.hasPolarSpread &&
        routing.polarSpreadLinesStorageId &&
        routing.polarSpreadPointsStorageId
      ) {
        const [route, timedRoute] = await Promise.all([
          getGeoJsonFromLocalForage(
            db.routings,
            routing.polarSpreadLinesStorageId,
          ),
          getGeoJsonFromLocalForage(
            db.routings,
            routing.polarSpreadPointsStorageId,
          ),
        ]);
        try {
          const modifiedTimedOptimalRoute = {
            ...timedRoute,
            features: timedRoute?.features.map((f) => ({
              ...f,
              properties: {
                ...f.properties,
                isMidnight: f.properties?.DT.endsWith('T00:00:00') || false,
              },
            })),
          };
          map
            .getSource(`${RoutingLayersSources.RoutingPolarSpreadLine}${index}`)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .setData(route || emptyGeojson);
          map
            .getSource(
              `${RoutingLayersSources.RoutingPolarSpreadPoints}${index}`,
            )
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .setData(modifiedTimedOptimalRoute || emptyGeojson);
        } catch (error) {
          map
            .getSource(`${RoutingLayersSources.OptimalRoute}${index}`)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .setData(emptyGeojson);
          map
            .getSource(`${RoutingLayersSources.TimedOptimalRoute}${index}`)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .setData(emptyGeojson);
        }
      }
      if (routing.allRoutingStorageId) {
        const allRoutes = await getGeoJsonFromLocalForage(
          db.routings,
          routing.allRoutingStorageId,
        );
        try {
          let modifiedAllRoutes = allRoutes;
          const allEndingTimes = allRoutes?.features.map(
            (f) => (f.properties?.etime as string) || '',
          );
          if (allEndingTimes && allRoutes) {
            allEndingTimes.sort();
            const fastest = allEndingTimes.shift();
            const slowest = allEndingTimes.pop();
            const step = allEndingTimes.length > 25 ? 1 : 2;
            const medianIndex = Math.round(allEndingTimes.length / 2);
            const firstGradientValue = 0 - medianIndex * step;
            modifiedAllRoutes = {
              ...allRoutes,
              features: allRoutes.features.map((f) => {
                let gradient = 0;
                let isMedian = false;
                const isSlowest = f.properties?.etime === slowest;
                const isFastest = f.properties?.etime === fastest;
                if (isSlowest) {
                  gradient = 50;
                } else if (isFastest) {
                  gradient = 50;
                } else {
                  const foundIndex = allEndingTimes.findIndex(
                    (v) => v === f.properties?.etime,
                  );
                  gradient = firstGradientValue + foundIndex * step;
                  if (foundIndex === medianIndex) {
                    isMedian = true;
                    gradient = 50;
                  }
                }

                return {
                  ...f,
                  id: f.properties?.name,
                  properties: {
                    ...f.properties,
                    name: Number(f.properties?.name),
                    gradient,
                    isMedian,
                    isSlowest,
                    isFastest,
                    isDashed: isSlowest || isFastest || isMedian,
                  },
                };
              }),
            };
          }
          map
            .getSource(`${RoutingLayersSources.OptimalRoute}${index}`)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .setData(modifiedAllRoutes);
          map
            .getSource(`${RoutingLayersSources.TimedOptimalRoute}${index}`)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .setData(emptyGeojson);
        } catch (error) {
          if (map) {
            map
              .getSource(`${RoutingLayersSources.OptimalRoute}${index}`)
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              .setData(emptyGeojson);
            map
              .getSource(`${RoutingLayersSources.TimedOptimalRoute}${index}`)
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              .setData(emptyGeojson);
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const clearSources = useCallback(
    async (index: number) => {
      try {
        map
          .getSource(`${RoutingLayersSources.OptimalRoute}${index}`)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .setData(emptyGeojson);
        map
          .getSource(`${RoutingLayersSources.TimedOptimalRoute}${index}`)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .setData(emptyGeojson);
      } catch (error) {
        console.log(
          'Error getting source :' +
            `${RoutingLayersSources.OptimalRoute}${index}`,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const changeLayerVisibility = useCallback(
    (hide: boolean, layerName: string) => {
      if (hide) {
        map.setLayoutProperty(layerName, 'visibility', 'none');
      } else {
        map.setLayoutProperty(layerName, 'visibility', 'visible');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    changeLayerVisibility(!isRoutingDataOn, RoutingLayers.Izochrones);
  }, [changeLayerVisibility, isRoutingDataOn]);

  useDeepCompareEffect(() => {
    ReactTooltip.rebuild();
  }, [sideMenuRoutingIds]);

  const closePlotModal = useCallback(() => {
    setRoutingPlotId(null);
  }, []);

  return (
    <MainContainer>
      <RoutingsContainer>
        {sideMenuRoutingIds.map((id, index) => (
          <RoutingItem
            key={id}
            id={id}
            index={index}
            handleSourceChange={handleSourceChange}
            changeLayerVisibility={changeLayerVisibility}
            clearSources={clearSources}
            setRoutingPlotId={setRoutingPlotId}
          />
        ))}
      </RoutingsContainer>
      <Button
        isSecondary
        stretch
        clickHandler={openAllRouts}
        label="View all routings"
      />
      <Modal
        isOpen={isAllRoutesOpen}
        width={600}
        title="All routings"
        closeModal={closeAllRoutes}
        onRequestClose={closeAllRoutes}
      >
        <AllRoutes />
      </Modal>
      <AsembleRoutingStatsModal
        routingId={routingPlotId}
        closePlotModal={closePlotModal}
      />
    </MainContainer>
  );
};

export default React.memo(RouteList);
