import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  convertDMSToDD,
  getDegreesMinutesAndSecondsArray,
  getDirection,
} from 'modules/coordinates';
import { LocationSelectors, LocationActions } from '../redux';
import { CustomLocationForm } from '../types';

export const useSetCustomCurrentLocation = (
  onSuccess: () => void,
): {
  initialValues: CustomLocationForm;
  handleSetCustomCurrentLocation: (data: CustomLocationForm) => void;
} => {
  const dispatch = useDispatch();
  const { coordinates: currentCoordinates = [0, 0], heading } = useSelector(
    LocationSelectors.lastLocation,
    () => true,
  );
  const initialValues = useMemo(
    () => ({
      latitude: currentCoordinates
        ? getDegreesMinutesAndSecondsArray(currentCoordinates[1])
        : [0, 0, 0],
      longitude: currentCoordinates
        ? getDegreesMinutesAndSecondsArray(currentCoordinates[0])
        : [0, 0, 0],
      latitudeDirection:
        currentCoordinates && getDirection(currentCoordinates[1], true),
      longitudeDirection:
        currentCoordinates && getDirection(currentCoordinates[0], false),
      heading: heading || 0,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSetCustomCurrentLocation = useCallback(
    (data: CustomLocationForm) => {
      const coordinates = [
        convertDMSToDD(data.longitude, data.longitudeDirection),
        convertDMSToDD(data.latitude, data.latitudeDirection),
      ];
      dispatch(
        LocationActions.addLocationToHistory({
          coordinates,
          heading: data.heading,
          timestamp: new Date().toISOString(),
        }),
      );
      onSuccess();
    },
    [onSuccess, dispatch],
  );

  return {
    initialValues,
    handleSetCustomCurrentLocation,
  };
};
