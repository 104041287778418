import React, { memo, useCallback, useMemo, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  FormCheckbox,
  TextInput,
  InputLabel,
  SelectInput,
  RadioButton,
  Tooltip,
  TooltipInfoIcon,
  Modal,
  InputError,
} from 'modules/ui/components';
import {
  settingsDefaultsWithDefaultValuesSelector,
  settingsWindSpeedCorrectionSelector,
} from 'modules/settings';
import WindSpeedCorrections from 'modules/settings/components/WindSpeedCorrections';
import { RegularSm } from 'modules/ui/typography';
import { Route } from 'modules/route/types';
import {
  getDegreesMinutesAndSecondsArray,
  getNauticalMilesDistance,
  getDirection,
  convertDMSToDD,
  CoordinatesInput,
} from 'modules/coordinates';
import {
  ExclusionZonesSelectors,
  useCheckCoordinatesInclusion,
} from 'modules/exclusionZones';
import {
  InclusionZonesSelectors,
  useCheckCoordinatesInclusionInInclusionZone,
} from 'modules/inclusionZones';
import {
  validateRequiredNumber,
  validateRequiredString,
  validateSpeedLimit,
  parseId,
} from 'modules/form';
import { LocationSelectors } from 'modules/location';
import { submitRoutingThunk } from '../../redux/thunk';
import { NewRouteForm } from '../../types';
import { hourOptions, minutesOptions } from '../../consts';
import { useGetTimeOptions } from '../../hooks';
import { getExclusionZoneLabel } from '../../utils';

import {
  Container,
  FooterContainer,
  FormContainer,
  ContentRow,
  Title,
  Separator,
  CoordinatesContainer,
  TimePickersContainer,
  TimePickersValues,
  CheckboxContainer,
} from './styledComponents';

type Props = {
  coordinatesTo?: number[];
  coordinatesFrom?: number[] | null;
  closeModal: () => void;
  inputRoute?: Route;
  isOpen: boolean;
};

const NewRouting: React.FC<Props> = ({
  coordinatesTo,
  coordinatesFrom,
  closeModal,
  inputRoute,
}) => {
  const dispatch = useDispatch();
  const currentCoordinates = useSelector(
    LocationSelectors.currentCoordinatesWithDefault,
    () => true,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const defaults = useSelector(settingsDefaultsWithDefaultValuesSelector);
  const windSpeedCorrection = useSelector(settingsWindSpeedCorrectionSelector);
  const exclusionZones = useSelector(
    ExclusionZonesSelectors.exclusionZonesList,
  );
  const enabledZonesCount = useSelector(
    ExclusionZonesSelectors.enabledZonesCount,
  );
  const inclusionZonesOptions = useSelector(
    InclusionZonesSelectors.inclusionZonesDropdownListOptions,
  );
  const inclusionZones = useSelector(
    InclusionZonesSelectors.inclusionZonesList,
  );

  const {
    validateStartAndEndCoordinatesWithExclusionZones,
  } = useCheckCoordinatesInclusion(exclusionZones);

  const {
    validateStartAndEndCoordinatesWithInclusionZones,
  } = useCheckCoordinatesInclusionInInclusionZone(inclusionZones);

  const {
    datesOptions,
    todayHoursOptions,
    todayMinutesOptions,
  } = useGetTimeOptions();

  const initialValues = useMemo(
    () => {
      const utcHours = new Date().getUTCHours();
      const initialHours = utcHours < 10 ? `0${utcHours}` : utcHours.toString();
      let intialDay = datesOptions[0].value;
      if (utcHours < 5) {
        intialDay = datesOptions[1].value;
      }
      return {
        ...defaults,
        windSpeedCorrection,
        startNow: true,
        startDate: intialDay,
        startHour: initialHours,
        startMinute: todayMinutesOptions[0].value,
        routeStartLng: getDegreesMinutesAndSecondsArray(
          (coordinatesFrom && coordinatesFrom[0]) || currentCoordinates[0],
        ),
        routeStartLat: getDegreesMinutesAndSecondsArray(
          (coordinatesFrom && coordinatesFrom[1]) || currentCoordinates[1],
        ),
        routeEndLng: getDegreesMinutesAndSecondsArray(
          coordinatesTo ? coordinatesTo[0] : 0,
        ),
        routeEndLat: getDegreesMinutesAndSecondsArray(
          coordinatesTo ? coordinatesTo[1] : 0,
        ),
        routeStartLngDirection: getDirection(
          (coordinatesFrom && coordinatesFrom[0]) || currentCoordinates[0],
        ),
        routeStartLatDirection: getDirection(
          (coordinatesFrom && coordinatesFrom[1]) || currentCoordinates[1],
          true,
        ),
        routeEndLngDirection: getDirection(
          coordinatesTo ? coordinatesTo[0] : 0,
        ),
        routeEndLatDirection: getDirection(
          coordinatesTo ? coordinatesTo[1] : 0,
          true,
        ),
        exclusionZones: !!enabledZonesCount,
        stayInInclusionZone: false,
        inclusionZoneId: inclusionZonesOptions[0]?.value,
        route:
          inputRoute && inputRoute.route.map(({ coordinates }) => coordinates),
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onSubmitStop = useCallback(() => setIsSubmitting(false), []);

  const onSubmit = useCallback(
    async (data: NewRouteForm) => {
      setIsSubmitting(true);
      dispatch(submitRoutingThunk(data, closeModal, onSubmitStop));
    },
    [closeModal, dispatch, onSubmitStop],
  );
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({
        handleSubmit,
        pristine,
        invalid,
        values,
        form,
        errors,
      }): React.ReactNode => (
        <>
          <Container>
            <FormContainer>
              {!!inputRoute && (
                <ContentRow>
                  <Title>{`Route: ${inputRoute.name}`}</Title>
                </ContentRow>
              )}
              <ContentRow>
                <Field
                  name="name"
                  type="text"
                  validate={validateRequiredString}
                  component={TextInput}
                  width={320}
                  label="Routing name"
                  autoFocus
                />
                <CoordinatesContainer>
                  <RegularSm>
                    {`Distance: ${getNauticalMilesDistance(
                      [
                        convertDMSToDD(
                          values.routeStartLng,
                          values.routeStartLngDirection,
                        ),
                        convertDMSToDD(
                          values.routeStartLat,
                          values.routeStartLatDirection,
                        ),
                      ],
                      [
                        convertDMSToDD(
                          values.routeEndLng,
                          values.routeEndLngDirection,
                        ),
                        convertDMSToDD(
                          values.routeEndLat,
                          values.routeEndLatDirection,
                        ),
                      ],
                    )} Nm`}
                  </RegularSm>
                </CoordinatesContainer>
              </ContentRow>
              {!inputRoute && (
                <>
                  <ContentRow marginTop={24}>
                    <InputLabel>Start coordinates</InputLabel>
                  </ContentRow>
                  <ContentRow>
                    <CoordinatesInput isLat name="routeStartLat" />
                    <CoordinatesInput name="routeStartLng" />
                  </ContentRow>
                  <ContentRow marginTop={24}>
                    <InputLabel>End coordinates</InputLabel>
                  </ContentRow>
                  <ContentRow>
                    <CoordinatesInput isLat name="routeEndLat" />
                    <CoordinatesInput name="routeEndLng" />
                  </ContentRow>
                </>
              )}
              <ContentRow marginTop={24}>
                <InputLabel>Start time</InputLabel>
              </ContentRow>
              <ContentRow>
                <RadioButton
                  isChecked={values.startNow}
                  label="Start now"
                  value=""
                  onChange={(): void =>
                    form.change('startNow', !values.startNow)
                  }
                />
                <TimePickersContainer>
                  <div style={{ marginBottom: 16 }}>
                    <RadioButton
                      isChecked={!values.startNow}
                      value=""
                      label=""
                      onChange={(): void =>
                        form.change('startNow', !values.startNow)
                      }
                    />
                  </div>
                  <TimePickersValues active={!values.startNow}>
                    <Field
                      name="startDate"
                      options={datesOptions}
                      component={SelectInput}
                      width={100}
                      change={form.change}
                    />
                    <Field
                      name="startHour"
                      options={
                        datesOptions[0].value === values.startDate
                          ? todayHoursOptions
                          : hourOptions
                      }
                      component={SelectInput}
                      width={80}
                      change={form.change}
                    />
                    <Field
                      name="startMinute"
                      options={
                        datesOptions[0].value === values.startDate &&
                        todayHoursOptions[0].value === values.startHour
                          ? todayMinutesOptions
                          : minutesOptions
                      }
                      component={SelectInput}
                      width={80}
                      change={form.change}
                    />
                    <RegularSm>UTC</RegularSm>
                  </TimePickersValues>
                </TimePickersContainer>
              </ContentRow>
              <Separator />
              <Title>Model</Title>
              <ContentRow>
                <div>
                  <Field
                    name="ecmwf"
                    component={FormCheckbox}
                    type="checkbox"
                    label="ECMWF"
                  />
                </div>
                <div>
                  <Field
                    name="gfs"
                    component={FormCheckbox}
                    type="checkbox"
                    label="GFS"
                  />
                </div>
                <CheckboxContainer hideMargin>
                  <Field
                    name="ecmwfEnsemble"
                    component={FormCheckbox}
                    type="checkbox"
                    label="ECMWF Ensemble"
                  />
                  <TooltipInfoIcon text="ECWMF Ensemble model consists of 50 separate forecasts<br> (ensemble members), and the routing is computed for each member." />
                </CheckboxContainer>
                <CheckboxContainer hideMargin>
                  <Field
                    name="gfsEnsemble"
                    component={FormCheckbox}
                    type="checkbox"
                    label="GFS Ensemble (GEFS)"
                  />
                  <TooltipInfoIcon text="Global Ensemble Forecast System (GEFS) routing model consists of 30 separate forecasts (ensemble members), and the routing is computed for each member." />
                </CheckboxContainer>
              </ContentRow>
              <Separator />
              <Title noMargin>Corrections</Title>
              <WindSpeedCorrections />
              <ContentRow marginTop={16}>
                <Field
                  name="waveCorrection"
                  parse={parseFloat}
                  component={TextInput}
                  label="Wave correction"
                  rightInlineLabel="%"
                  width={136}
                  validate={validateRequiredNumber}
                />
                <Field
                  name="currentCorrection"
                  parse={parseFloat}
                  component={TextInput}
                  label="Current correction"
                  rightInlineLabel="%"
                  width={136}
                  validate={validateRequiredNumber}
                />
              </ContentRow>
              <Separator />
              <Title noMargin>Zones</Title>
              <ContentRow>
                <CheckboxContainer>
                  <Field
                    name="exclusionZones"
                    component={FormCheckbox}
                    type="checkbox"
                    label={getExclusionZoneLabel(exclusionZones)}
                    disabled={!enabledZonesCount}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    validate={validateStartAndEndCoordinatesWithExclusionZones}
                  />
                  <Tooltip
                    multiline
                    text="If checked, the routing will avoid entering all of the created exclusion zones. You can add new exclusion zones using the right-side menu on the main screen."
                  />
                </CheckboxContainer>
              </ContentRow>
              {errors.exclusionZones && (
                <InputError respectWhiteSpace>
                  {errors.exclusionZones}
                </InputError>
              )}
              <ContentRow marginTop={16}>
                <Field
                  name="stayInInclusionZone"
                  component={FormCheckbox}
                  type="checkbox"
                  label="Stay within an inclusion zone"
                  disabled={!inclusionZonesOptions.length}
                />
                <Field
                  name="inclusionZoneId"
                  component={SelectInput}
                  change={form.change}
                  parse={parseId}
                  options={inclusionZonesOptions}
                  disabled={!inclusionZonesOptions.length}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  validate={validateStartAndEndCoordinatesWithInclusionZones}
                />
                <Tooltip
                  multiline
                  text="If checked, the routing will stay within the selected inclusion zone from start to finish. You can add a new inclusion zone using the right-side menu on the main screen"
                />
              </ContentRow>
              {errors.inclusionZoneId && (
                <InputError respectWhiteSpace>
                  {errors.inclusionZoneId}
                </InputError>
              )}
              <Title>Settings</Title>
              <ContentRow>
                <Field
                  name="polarEfficiency"
                  parse={parseFloat}
                  component={TextInput}
                  label="Polar efficiency"
                  rightInlineLabel="%"
                  width={136}
                  validate={validateRequiredNumber}
                />
                <CheckboxContainer>
                  <Field
                    name="polarSpreadCalculation"
                    component={FormCheckbox}
                    type="checkbox"
                    label="Add polar spread calculation"
                    disabled={!(values.ecmwf || values.gfs)}
                  />
                  <Tooltip
                    multiline
                    text="For deterministic models (ECMWF, GFS) the polar spread will also calculate the routes for<br> 80%, 85%, 90%, 95%, 100%, 105%, 110%, 115%, and 120% polar efficiencies."
                  />
                </CheckboxContainer>
              </ContentRow>
              <ContentRow marginTop={24}>
                <Field
                  name="tackPenalty"
                  parse={parseFloat}
                  component={TextInput}
                  label="Tack penalty"
                  rightInlineLabel="min"
                  width={136}
                  validate={validateRequiredNumber}
                />
                <Field
                  name="gybePenalty"
                  parse={parseFloat}
                  component={TextInput}
                  label="Gybe penalty"
                  rightInlineLabel="min"
                  width={136}
                  validate={validateRequiredNumber}
                />
                <Field
                  name="sailChangePenalty"
                  parse={parseFloat}
                  component={TextInput}
                  label="Sail change penalty"
                  rightInlineLabel="min"
                  width={136}
                  validate={validateRequiredNumber}
                />
              </ContentRow>
              <ContentRow marginTop={24}>
                <Field
                  name="speedLimit"
                  parse={parseFloat}
                  component={TextInput}
                  label="Wind speed limit"
                  rightInlineLabel="kts"
                  width={136}
                  validate={validateSpeedLimit}
                />
                <Field
                  name="waveHeightLimit"
                  parse={parseFloat}
                  component={TextInput}
                  label="Wave height limit"
                  rightInlineLabel="m"
                  width={136}
                  validate={validateRequiredNumber}
                />
              </ContentRow>
            </FormContainer>
          </Container>
          <FooterContainer>
            <Button isSecondary label="Cancel" clickHandler={closeModal} />
            <Button
              isLoading={isSubmitting}
              disabled={
                isSubmitting ||
                pristine ||
                invalid ||
                !(
                  values.gfs ||
                  values.ecmwf ||
                  values.ecmwfEnsemble ||
                  values.gfsEnsemble
                )
              }
              label="Create route"
              clickHandler={handleSubmit}
            />
          </FooterContainer>
        </>
      )}
    />
  );
};

const NewRoutingForm = React.memo(NewRouting);

const NewRouteModal: React.FC<Props> = (props) => (
  <Modal
    isOpen={props.isOpen}
    closeModal={props.closeModal}
    width={766}
    title="New routing"
  >
    <NewRoutingForm {...props} />
  </Modal>
);

export default memo(NewRouteModal);
