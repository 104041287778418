import React from 'react';
import { FieldRenderProps } from 'react-final-form';

import InputLabel from '../InputLabel';
import InputError from '../InputError';

import {
  Container,
  Input,
  InputContainer,
  RightLabel,
  ErrorLabelContainer,
} from './styledComponents';

type Props = {
  rightInlineLabel?: string;
  label?: string;
  type?: string;
  width?: number;
  autoFocus?: boolean;
  min?: number;
  max?: number;
  forceFraction?: boolean;
  isSmall?: boolean;
} & FieldRenderProps<number | string, HTMLInputElement>;

const formatFraction = (value: number): string => {
  if (value < 10) {
    return `00${value}`;
  }
  if (value < 100) {
    return `0${value}`;
  }
  return value.toString();
};

const TextInput: React.FC<Props> = ({
  rightInlineLabel,
  label,
  input,
  type,
  width,
  autoFocus,
  max,
  min,
  forceFraction,
  isSmall,
  meta: { invalid, touched, error, dirty, active },
}) => (
  <Container>
    {label && <InputLabel invalid={!!(invalid && touched)}>{label}</InputLabel>}
    <InputContainer
      width={width}
      invalid={!!(invalid && touched)}
      active={active || (!touched && autoFocus)}
    >
      <Input
        isSmall={isSmall}
        type={type || 'number'}
        {...input}
        value={
          !dirty && forceFraction
            ? formatFraction(input.value as number)
            : input.value
        }
        autoFocus={!!autoFocus}
        max={max}
        min={min}
      />
      {rightInlineLabel && <RightLabel>{rightInlineLabel}</RightLabel>}
    </InputContainer>
    {touched && error && (
      <ErrorLabelContainer>
        <InputError>{error}</InputError>
      </ErrorLabelContainer>
    )}
  </Container>
);

export default React.memo(TextInput);
