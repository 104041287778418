import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'modules/main/redux';
import { useToggle } from 'modules/main';
import { RegularSm, RegularXs } from 'modules/ui/typography';
import {
  Button,
  Checkbox,
  ColorIndicator,
  Chips,
  InnerCollapsibleContainer,
} from 'modules/ui/components';
import {
  isRoutingDataOnSelector,
  turnOnShowRoutingDataFlag,
} from 'modules/metadata';
import { RoutingLayers, RoutingColors } from 'modules/mapLayers/consts';
import {
  clearRoutingIsochrones,
  toggleRoutingIsochrones,
  toogleActiveRoutings,
} from 'modules/routing/redux';
import { RoutingType } from 'modules/routing/consts';
import { formatPolarSpreadTooltip } from 'modules/routing/utils/tooltip';
import { formatModelName } from 'modules/routing/utils/formater';
import { useDownloadRoutingCsv } from '../../hooks';
import {
  activeIsochronesStorageKeySelector,
  downloadedRoutingByIdSelector,
} from '../../redux/selectors';
import { formatIsoTime } from '../../utils/time';
import { DownloadedRoute } from '../../types';

import { ButtonsContainer, ButtonsLinksContainer } from './styledComponents';

type Props = {
  id: string;
  index: number;
  handleSourceChange: (
    routing: DownloadedRoute,
    index: number,
  ) => Promise<void>;
  changeLayerVisibility: (hide: boolean, layerName: string) => void;
  clearSources: (index: number) => void;
  setRoutingPlotId: (id: string | null) => void;
};

const ItemUi: React.FC<Props> = ({
  id,
  index,
  handleSourceChange,
  changeLayerVisibility,
  clearSources,
  setRoutingPlotId,
}) => {
  const dispatch = useDispatch();
  const [isOpened, toggleIsOpened] = useToggle(false);
  const isRoutingDataOn = useSelector(isRoutingDataOnSelector);
  const [
    isRouteDisplayed,
    toggleIsRouteDisplayed,
    setIsRouteDisplayed,
  ] = useToggle(false);
  const [isPolarSpreadDisplayed, toggleIsPolarSpreadDisplayed] = useToggle(
    false,
  );
  const routing = useSelector((state: RootState) =>
    downloadedRoutingByIdSelector(state, id),
  );

  const tooltipMessage = useMemo(() => {
    return formatPolarSpreadTooltip(
      routing?.metadata?.Statistics?.startTime,
      routing?.polarSpreadMetadata,
    );
  }, [routing]);

  const activeIsochronesStorageKey = useSelector(
    activeIsochronesStorageKeySelector,
  );

  const toggleIsochrones = useCallback(() => {
    dispatch(toggleRoutingIsochrones(routing?.izochronesStorageId || ''));
    setIsRouteDisplayed(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routing?.izochronesStorageId, dispatch]);

  useEffect(() => {
    return (): void => {
      dispatch(clearRoutingIsochrones(routing?.izochronesStorageId || ''));
      clearSources(index);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openMeteogram = useCallback(() => {
    window.open(`/meteogram/routing/${id}`, '_blank');
  }, [id]);

  const openRoutingTable = useCallback(() => {
    window.open(`/table/${id}`, '_blank');
  }, [id]);

  const { handleDownloadCsv, csvDownloading } = useDownloadRoutingCsv(id);

  useEffect(() => {
    if (
      isRouteDisplayed &&
      activeIsochronesStorageKey !== routing?.izochronesStorageId
    ) {
      dispatch(toggleRoutingIsochrones(routing?.izochronesStorageId || ''));
    }
    if (
      !isRouteDisplayed &&
      activeIsochronesStorageKey === routing?.izochronesStorageId
    ) {
      dispatch(toggleRoutingIsochrones(routing?.izochronesStorageId || ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRouteDisplayed]);

  const toggleRouting = useCallback(() => {
    toggleIsRouteDisplayed();
    if (routing?.id) {
      dispatch(toogleActiveRoutings(routing?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routing?.id]);

  useEffect(() => {
    if (routing) {
      handleSourceChange(routing, index);
    }
  }, [routing, handleSourceChange, index]);

  useEffect(() => {
    changeLayerVisibility(
      !(isRoutingDataOn && isRouteDisplayed),
      `${RoutingLayers.TimedOptimalRouteBarbs}${index}`,
    );
    changeLayerVisibility(
      !(isRoutingDataOn && isRouteDisplayed),
      `${RoutingLayers.TimedOptimalRoute}${index}`,
    );
    changeLayerVisibility(
      !(isRoutingDataOn && isRouteDisplayed),
      `${RoutingLayers.OptimalRoute}${index}`,
    );
    changeLayerVisibility(
      !(isRoutingDataOn && isRouteDisplayed),
      `${RoutingLayers.DashedOptimalRoute}${index}`,
    );
    changeLayerVisibility(
      !(isRoutingDataOn && isRouteDisplayed),
      `${RoutingLayers.RoutingTimedLocation}${index}`,
    );
  }, [isRouteDisplayed, isRoutingDataOn, changeLayerVisibility, index]);

  useEffect(() => {
    changeLayerVisibility(
      !(isRoutingDataOn && isPolarSpreadDisplayed),
      `${RoutingLayers.RoutingPolarSpreadLine}${index}`,
    );
    changeLayerVisibility(
      !(isRoutingDataOn && isPolarSpreadDisplayed),
      `${RoutingLayers.RoutingPolarSpreadPoints}${index}`,
    );
    changeLayerVisibility(
      !(isRoutingDataOn && isPolarSpreadDisplayed),
      `${RoutingLayers.RoutingPolarSpreadTimedLocation}${index}`,
    );
  }, [isPolarSpreadDisplayed, isRoutingDataOn, changeLayerVisibility, index]);

  useEffect(() => {
    if (isRouteDisplayed || isPolarSpreadDisplayed) {
      dispatch(turnOnShowRoutingDataFlag());
    }
  }, [dispatch, isRouteDisplayed, isPolarSpreadDisplayed]);

  useEffect(() => {
    if (!isRouteDisplayed && isPolarSpreadDisplayed) {
      toggleIsRouteDisplayed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPolarSpreadDisplayed]);

  useEffect(() => {
    if (
      isRouteDisplayed &&
      !isPolarSpreadDisplayed &&
      routing?.hasPolarSpread
    ) {
      toggleIsPolarSpreadDisplayed();
    }
    if (
      !isRouteDisplayed &&
      isPolarSpreadDisplayed &&
      routing?.hasPolarSpread
    ) {
      toggleIsPolarSpreadDisplayed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRouteDisplayed]);

  const openEnsembleStatsModal = useCallback(() => {
    setRoutingPlotId(routing?.id || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setRoutingPlotId, routing?.id]);

  if (!routing) return null;

  return (
    <InnerCollapsibleContainer
      isOpened={isOpened}
      toggleIsOpened={toggleIsOpened}
      title={
        routing.metadata?.Internal?.routingName || `Route ID: ${routing.id}`
      }
      titleComponent={<ColorIndicator color={RoutingColors[index]} />}
    >
      <RegularSm>
        {`${formatIsoTime(
          routing.metadata?.Route?.StartTime ||
            routing.metadata?.Statistics?.startTime,
        )} ${
          routing.metadata?.Route?.time2finish
            ? `- ${formatIsoTime(routing.metadata?.Route?.time2finish)}`
            : ''
        } UTC. ${
          routing.metadata?.Route?.DistGCL ||
          routing.metadata?.Route?.DistSailed
            ? `${Number(
                routing.metadata?.Route?.DistGCL ||
                  routing.metadata?.Route?.DistSailed,
              ).toFixed(2)} Nm.`
            : ''
        } `}
      </RegularSm>
      <ButtonsContainer>
        <Checkbox
          value={isRouteDisplayed}
          label={formatModelName(
            routing.metadata?.Meteo?.Model,
            routing.metadata?.Meteo?.Type,
          )}
          name={formatModelName(
            routing.metadata?.Meteo?.Model,
            routing.metadata?.Meteo?.Type,
          )}
          isSecondary={false}
          onChange={toggleRouting}
        />
        <ButtonsLinksContainer>
          {routing.metadata?.Meteo?.Type !== RoutingType.eps && (
            <>
              <Button
                isSmall
                isSecondary
                label="Table"
                clickHandler={openRoutingTable}
              />
              {!!routing.meteogramStorageId && (
                <Button
                  isSmall
                  isSecondary
                  label="Meteogram"
                  clickHandler={openMeteogram}
                />
              )}
              <Button
                isLoading={csvDownloading}
                isSmall
                isSecondary
                label="CSV"
                clickHandler={handleDownloadCsv}
              />
            </>
          )}
          {routing.metadata?.Meteo?.Type === RoutingType.eps &&
            !!routing.allRoutingStorageId && (
              <Button
                isSmall
                isSecondary
                label="Stats"
                clickHandler={openEnsembleStatsModal}
              />
            )}
        </ButtonsLinksContainer>
      </ButtonsContainer>
      <div>
        {!!routing.izochronesStorageId &&
          routing.metadata?.Meteo?.Type !== RoutingType.eps && (
            <>
              <Chips
                label="Isochrones"
                onClick={toggleIsochrones}
                name={routing.izochronesStorageId || ''}
                value={
                  routing.izochronesStorageId === activeIsochronesStorageKey
                }
              />
            </>
          )}
        {!!routing.izochronesStorageId &&
          routing.metadata?.Meteo?.Type === RoutingType.eps && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Chips
                label="Isochrones"
                onClick={toggleIsochrones}
                name={routing.izochronesStorageId || ''}
                value={
                  routing.izochronesStorageId === activeIsochronesStorageKey
                }
              />
              {routing.izochronesStorageId === activeIsochronesStorageKey && (
                <RegularXs style={{ marginBottom: 8, maxWidth: 200 }}>
                  Move your mouse over each ensemble to view their isochrones.
                </RegularXs>
              )}
            </div>
          )}
        {routing.hasPolarSpread && (
          <Chips
            tooltipText={tooltipMessage}
            label="Polar spread"
            onClick={toggleIsPolarSpreadDisplayed}
            name={routing.polarSpreadLinesStorageId || ''}
            value={isPolarSpreadDisplayed}
          />
        )}
      </div>
    </InnerCollapsibleContainer>
  );
};

export default React.memo(ItemUi);
