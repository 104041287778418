import { differenceInHours, parseISO } from 'date-fns';

import { ThunkAction } from 'modules/main/redux';
import db from 'modules/localForage/db';
import {
  storeToLocalForage,
  deleteMultipleFromLocalForage,
} from 'modules/localForage/utils';
import { BoatsSelectors } from 'modules/boats';
import { addNotification } from 'modules/notifications/redux';
import { NotificationTypes } from 'modules/notifications';
import { firstDataSetsDateSelector, setDataSetTimeOffset } from 'modules/meteo';
import { convertDMSToDD } from 'modules/coordinates';
import { RoutingApi } from '../api';
import { Route, NewRouteForm } from '../types';
import { downloadedRoutingByIdSelector } from './selectors';
import {
  addDownloadedRouting,
  addServerRoutes,
  removeDownloadedRouting,
  removeServerRouting,
  setHasNewServerRouteForRefresh,
  toggleSideMenuRouting,
} from './actions';
import { RoutingType } from '../consts';
import { getModelsArray } from '../utils';

export const downloadServerRoutingsListThunk = (
  onEnd: () => void,
): ThunkAction<void> => async (dispatch, getState): Promise<void> => {
  try {
    const state = getState();
    const boat = BoatsSelectors.firstBoat(state);
    const routes = await RoutingApi.listAllServerRoutings(boat.id);
    dispatch(addServerRoutes({ list: routes, time: new Date().getTime() }));
    dispatch(
      addNotification({
        type: NotificationTypes.Success,
        message: 'Server routings list updated.',
      }),
    );
  } catch (error) {
    dispatch(
      addNotification({
        type: NotificationTypes.Error,
        message: 'Routing data currently not available',
      }),
    );
  } finally {
    onEnd();
  }
};

export const downloadServerRoutingFilesThunk = (
  {
    id,

    polarEfficiency,
  }: Route,
  onFinish: () => void,
): ThunkAction<void> => async (dispatch): Promise<void> => {
  const optimalRouteId = `route-${new Date().getTime()}`;
  const timedOptimalRouteId = `timedRoute-${new Date().getTime()}`;
  const izochronesId = `izochrones-${new Date().getTime()}`;
  const meteogramId = `meteogram-${new Date().getTime()}`;
  const allRoutingsId = `allRoutings-${new Date().getTime()}`;
  const polarSpreadPointsId = `polarSpreadPoints-${new Date().getTime()}`;
  const polarSpreadLinesId = `polarSpreadLines-${new Date().getTime()}`;
  try {
    const routingFiles = await RoutingApi.getRouting(id);
    if (routingFiles.metadata?.Meteo?.Type === RoutingType.fc) {
      await Promise.all([
        storeToLocalForage(
          db.routings,
          optimalRouteId,
          routingFiles.optimalRoute,
        ),
        storeToLocalForage(
          db.routings,
          timedOptimalRouteId,
          routingFiles.timedOptimalRoute,
        ),
        routingFiles.izochrones
          ? storeToLocalForage(
              db.routings,
              izochronesId,
              routingFiles.izochrones,
            )
          : undefined,
        routingFiles.meteogram
          ? storeToLocalForage(db.routings, meteogramId, routingFiles.meteogram)
          : undefined,
      ]);
      if (routingFiles.polarSpreads) {
        await Promise.all([
          storeToLocalForage(
            db.routings,
            polarSpreadPointsId,
            routingFiles.polarSpreads.allPoints,
          ),
          storeToLocalForage(
            db.routings,
            polarSpreadLinesId,
            routingFiles.polarSpreads.multiLine,
          ),
        ]);
      }
      dispatch(
        addDownloadedRouting({
          id,
          izochronesStorageId: routingFiles.izochrones
            ? izochronesId
            : undefined,
          metadata: routingFiles.metadata,
          optimalRouteStorageId: optimalRouteId,
          timedOptimalRouteStorageId: timedOptimalRouteId,
          meteogramStorageId: routingFiles.meteogram ? meteogramId : undefined,
          polarSpreadLinesStorageId: routingFiles.polarSpreads
            ? polarSpreadLinesId
            : undefined,
          polarSpreadPointsStorageId: routingFiles.polarSpreads
            ? polarSpreadPointsId
            : undefined,
          hasPolarSpread: !!routingFiles.polarSpreads,
          polarSpreadMetadata: routingFiles.polarSpreads?.metadata,
          polarEfficiency,
        }),
      );
    } else if (routingFiles.metadata?.Meteo?.Type === RoutingType.eps) {
      await Promise.all([
        storeToLocalForage(
          db.routings,
          allRoutingsId,
          routingFiles.allRoutings,
        ),
        routingFiles.izochrones
          ? storeToLocalForage(
              db.routings,
              izochronesId,
              routingFiles.izochrones,
            )
          : undefined,
      ]);
      dispatch(
        addDownloadedRouting({
          id,
          allRoutingStorageId: allRoutingsId,
          metadata: routingFiles.metadata,
          izochronesStorageId: routingFiles.izochrones
            ? izochronesId
            : undefined,
          polarEfficiency,
        }),
      );
    } else {
      dispatch(
        addNotification({
          type: NotificationTypes.Error,
          message: 'Routing is missing metadata',
        }),
      );
      return;
    }
    dispatch(toggleSideMenuRouting(id));
    dispatch(
      addNotification({
        type: NotificationTypes.Success,
        message:
          'Routing successfully downloaded and is now available under Downloaded routings.',
      }),
    );
  } catch (error) {
    dispatch(
      addNotification({
        type: NotificationTypes.Error,
        message: 'Routing currently not available',
      }),
    );
  } finally {
    onFinish();
  }
};

export const removeDownloadedRoutingThunk = (
  id: string,
): ThunkAction<void> => async (dispatch, getState): Promise<void> => {
  const state = getState();
  const routing = downloadedRoutingByIdSelector(state, id);
  if (!routing) return;

  const idsForDelete = [
    routing.izochronesStorageId,
    routing.optimalRouteStorageId,
    routing.timedOptimalRouteStorageId,
    routing.allRoutingStorageId,
    routing.meteogramStorageId,
    routing.allRoutingStorageId,
    routing.polarSpreadLinesStorageId,
    routing.polarSpreadPointsStorageId,
  ].filter((key) => !!key) as string[];

  await deleteMultipleFromLocalForage(db.routings, idsForDelete);
  dispatch(removeDownloadedRouting(id));
};

export const submitRoutingThunk = (
  data: NewRouteForm,
  onSuccess: () => void,
  onSubmitStop: () => void,
): ThunkAction<void> => async (dispatch, getState): Promise<void> => {
  const state = getState();
  const boat = BoatsSelectors.firstBoat(state);
  try {
    const route = data.route || [
      [
        convertDMSToDD(data.routeStartLng, data.routeStartLngDirection),
        convertDMSToDD(data.routeStartLat, data.routeStartLatDirection),
      ],
      [
        convertDMSToDD(data.routeEndLng, data.routeEndLngDirection),
        convertDMSToDD(data.routeEndLat, data.routeEndLatDirection),
      ],
    ];
    const startTime =
      !data.startNow && data.startHour && data.startMinute && data.startDate
        ? `${data.startDate}T${data.startHour}:${data.startMinute}`
        : undefined;
    const models = getModelsArray(data);
    await RoutingApi.submitNewRoutingApiCall({
      waypoints: route,
      name: data.name,
      currentCorrection: data.currentCorrection,
      gybePenalty: data.gybePenalty,
      maxWave: data.waveHeightLimit,
      maxWind: data.speedLimit,
      obeyExclusionZones: data.exclusionZones,
      inclusionZoneId: data.stayInInclusionZone
        ? data.inclusionZoneId
        : undefined,
      polarEfficiency: data.polarEfficiency,
      polarSpreadCalculation: data.polarSpreadCalculation,
      startTime,
      sailChangePenalty: data.sailChangePenalty,
      tackPenalty: data.tackPenalty,
      waveCorrection: data.waveCorrection,
      models,
      windSpeedCorrections: data.windSpeedCorrection,
      boatId: boat.id,
    });
    dispatch(
      addNotification({
        type: NotificationTypes.Success,
        message: `Routing request ${data.name} successfully submitted. It is currently processing on the server, and it will be available for download shortly.`,
      }),
    );
    dispatch(setHasNewServerRouteForRefresh());
    onSuccess();
  } catch (error) {
    onSubmitStop();
    dispatch(
      addNotification({
        type: NotificationTypes.Error,
        message: 'Server error while submitting a new routing request.',
      }),
    );
  }
};

export const positionTimeOnRoutePointThunk = (
  pointDate: string,
): ThunkAction<void> => async (dispatch, getState): Promise<void> => {
  const state = getState();
  const firstDate = firstDataSetsDateSelector(state);
  const timelineOffset = differenceInHours(
    parseISO(pointDate),
    parseISO(firstDate),
  );
  if (timelineOffset >= 0 && timelineOffset < 240) {
    dispatch(setDataSetTimeOffset(timelineOffset));
  }
};

export const deleteServerRouteThunk = (
  routing: Route,
): ThunkAction<void> => async (dispatch): Promise<void> => {
  try {
    await RoutingApi.deleteServerRouting(routing.id);
    dispatch(removeServerRouting(routing.id));
    dispatch(
      addNotification({
        type: NotificationTypes.Success,
        message: 'Server routing has been deleted successfully.',
      }),
    );
  } catch (error) {
    dispatch(
      addNotification({
        type: NotificationTypes.Error,
        message: 'Error while deleting server routing.',
      }),
    );
  }
};
