import { useCallback, useEffect } from 'react';
import { Map } from 'mapbox-gl';
import { useSelector } from 'react-redux';
import { featureCollection, feature } from '@turf/helpers';

import { useToggle } from 'modules/main';
import { Layers, ZoneLayers, ZoneLayersSources } from 'modules/mapLayers';
import { ExclusionZonesSelectors } from '../redux';

export const useDisplayExclusionZones = (
  map: Map,
): {
  toggleExclusionZoneVisibility: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  isExclusionZoneVisibilityOn: boolean;
  hideExclusionZones: () => void;
  showExclusionZones: () => void;
} => {
  const exclusionZones = useSelector(
    ExclusionZonesSelectors.exclusionZonesList,
  );

  useEffect(() => {
    const exclusionZonesFeatureCollection = featureCollection(
      exclusionZones.map(({ exclusionZone, id, enabled }) =>
        feature(exclusionZone.geometry, {
          ...exclusionZone.properties,
          id,
          enabled,
        }),
      ),
    );

    map
      .getSource(ZoneLayersSources.ExclusionZones)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .setData(exclusionZonesFeatureCollection);
  }, [map, exclusionZones]);

  const [isExclusionZoneVisibilityOn, , setToggleValue] = useToggle();

  const hideExclusionZones = useCallback(() => {
    map.setLayoutProperty(Layers.IceZone, 'visibility', 'none');
    map.setLayoutProperty(ZoneLayers.ExclusionZones, 'visibility', 'none');
    map.setLayoutProperty(
      `${ZoneLayers.ExclusionZones}Outline`,
      'visibility',
      'none',
    );
    setToggleValue(false);
  }, [map, setToggleValue]);

  const showExclusionZones = useCallback(() => {
    map.setLayoutProperty(Layers.IceZone, 'visibility', 'visible');
    map.setLayoutProperty(ZoneLayers.ExclusionZones, 'visibility', 'visible');
    map.setLayoutProperty(
      `${ZoneLayers.ExclusionZones}Outline`,
      'visibility',
      'visible',
    );
    setToggleValue(true);
  }, [map, setToggleValue]);

  const toggleExclusionZoneVisibility = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
      const visibility = map.getLayoutProperty(
        ZoneLayers.ExclusionZones,
        'visibility',
      );
      if (visibility === 'visible') {
        hideExclusionZones();
      } else {
        showExclusionZones();
      }
    },
    [map, showExclusionZones, hideExclusionZones],
  );

  return {
    toggleExclusionZoneVisibility,
    isExclusionZoneVisibilityOn,
    hideExclusionZones,
    showExclusionZones,
  };
};
