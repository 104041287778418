import { ThunkAction } from 'modules/main/redux';
import { addNotification } from 'modules/notifications/redux';
import {
  addDownload,
  removeDownload,
  addToHistory,
  addToDownloadHistoryCounter,
} from 'modules/downloads';
import { DownloadType } from 'modules/downloads/consts';
import { formatISO } from 'modules/meteo/utils/time';
import { MeteoProviders } from 'modules/meteo/consts';
import { NotificationTypes } from 'modules/notifications';
import { addFullMeteogramInPoint } from './actions';
import { MeteogramApi } from '../api';

type Coordinates = {
  lat: number;
  lon: number;
};

type Meteograms = {
  ecmwf: boolean;
  gfs: boolean;
};

export const downloadFullMeteogramInPoint = (
  coordinates: Coordinates,
  meteograms: Meteograms,
): ThunkAction<void> => async (dispatch): Promise<void> => {
  const { lat, lon } = coordinates;
  const date = new Date();
  const downloadEcmwfName = `Full meteogram (ECMWF) ${formatISO(date)}`;
  const downloadGfsName = `Full meteogram (GFS) ${formatISO(date)}`;
  const downloadEcmwfId = `full-meteo-ecmwf-${date.getTime()}`;
  const downloadGfsId = `full-meteo-gfs-${date.getTime()}`;
  try {
    // let totalBytesEcmwf = 0;
    // let totalBytesGfs = 0;
    let ecmwfData = null;
    let gfsData = null;

    if (meteograms.ecmwf) {
      dispatch(
        addDownload({
          name: downloadEcmwfName,
          downloaded: false,
          id: downloadEcmwfId,
          type: DownloadType.Meteogram,
        }),
      );
    }

    if (meteograms.gfs) {
      dispatch(
        addDownload({
          name: downloadGfsName,
          downloaded: false,
          id: downloadGfsId,
          type: DownloadType.Meteogram,
        }),
      );
    }

    if (meteograms.ecmwf) {
      ecmwfData = await MeteogramApi.getMetegoram({
        lat,
        lon,
        model: MeteoProviders.ECMWF,
        step: 3,
      });

      /**
       const contentSizeEcmwf =
       Number((Number(totalBytesEcmwf) / 1024).toFixed(2)) || 4;
       */

      dispatch(
        addToHistory({
          name: downloadGfsName,
          id: downloadEcmwfId,
          type: DownloadType.Meteogram,
          // TODO fix this
          size: 4,
        }),
      );
      dispatch(addToDownloadHistoryCounter(4));
      dispatch(
        removeDownload({
          id: downloadEcmwfId,
        }),
      );
    }

    if (meteograms.gfs) {
      gfsData = await MeteogramApi.getMetegoram({
        lat,
        lon,
        model: MeteoProviders.NCEP,
        step: 3,
      });

      dispatch(
        addToHistory({
          name: downloadEcmwfName,
          id: downloadGfsId,
          type: DownloadType.Meteogram,
          // TODO fix this
          size: 4,
        }),
      );
      dispatch(addToDownloadHistoryCounter(4));
      dispatch(
        removeDownload({
          id: downloadGfsId,
        }),
      );
    }

    dispatch(
      addFullMeteogramInPoint({
        gfs: gfsData,
        ecmwf: ecmwfData,
      }),
    );
  } catch (error) {
    dispatch(
      addNotification({
        type: NotificationTypes.Error,
        message: error.toString(),
      }),
    );
  }
};
