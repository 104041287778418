import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { useModal } from 'modules/main';
import { LocationInputModal } from 'modules/location';
import { LocationSelectors } from 'modules/location/redux';
import { toDegreesMinutesAndSeconds } from 'modules/coordinates';
import { ActionItem } from '../ActionItem';

import { SubtitleContainer, CoordinatesText } from './styledComponents';

const LocationHeaderUi: React.FC = () => {
  const [isModalOpen, , openModal, closeModal] = useModal(false);
  const coordinates = useSelector(LocationSelectors.currentCoordinates);
  return (
    <>
      <LocationInputModal isOpen={isModalOpen} closeModal={closeModal} />
      <SubtitleContainer>
        <CoordinatesText>
          {coordinates
            ? `Boat location: ${toDegreesMinutesAndSeconds(
                coordinates[1],
                true,
                1,
              )} ${toDegreesMinutesAndSeconds(coordinates[0], false, 1)}`
            : 'No coordinates yet.'}
        </CoordinatesText>
        <ActionItem onClick={openModal} label="Update" />
      </SubtitleContainer>
    </>
  );
};

export const LocationHeader = memo(LocationHeaderUi);
